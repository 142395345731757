import React from 'react';
import { DzOwner, DzProjectStage } from 'shared-ui';
import { ProjectsViews } from 'shared/hooks/makeUseFavoriteViews';
import { getAddressLabel } from 'shared/utils/getAddressLabel';
import { isDummy, PopulatedProject, ProjectGridDummy } from './types';
import { Skeleton, Tooltip } from '@mui/material';
import {
  DzColDef,
  getDzCheckBoxRawHtml,
  getDzOwnerCellRawHtml,
  GridColumnTypes,
  ProjectNameWithFlare,
} from 'components/shared';
import { format, coerceToDate, addZeroTime } from '@one-vision/date-utils';
import { defaultSigningDatePlaceholder } from 'shared/constants';
import { ClassNameMap } from '@mui/styles';
import { CellClickedEvent, IHeaderParams } from 'ag-grid-community';
import DzGridSortableHeader from './DzGridSortableHeader.view';
import { SortDirection } from 'types';

export enum COLUMN_NAMES {
  NAME = 'name',
  ADDRESS = 'address.address1',
  PRIMARY_USER = 'primaryClient.firstName,primaryClient.lastName',
  PROJECT_STAGE = 'projectStageId',
  MANAGER = 'owners.firstName,owners.lastName',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  DESIRED_MEMBERSHIP = 'address.clientSelectedMembership',
  SIGNING_DATE = 'signingDate',
  TOS = 'address.tosAccepted',
  CREDIT_CARD = 'address.creditCardProvided',
  ESTIMATED_DAY1_DATE = 'estimatedDay1Date',
  DAY1_START_DATE = 'day1StartDate',
  LOST_REASON = 'projectLostReasonId',
  CPP_EXPIRATION_DATE = 'address.cppExpDate',
  SERVICE_LEVEL = 'address.clientActivatedMembership',
}

const ALL_PROJECTS_COLUMNS = [
  COLUMN_NAMES.NAME,
  COLUMN_NAMES.ADDRESS,
  COLUMN_NAMES.PRIMARY_USER,
  COLUMN_NAMES.PROJECT_STAGE,
  COLUMN_NAMES.SERVICE_LEVEL,
  COLUMN_NAMES.TOS,
  COLUMN_NAMES.CREDIT_CARD,
  COLUMN_NAMES.MANAGER,
  COLUMN_NAMES.CREATED_AT,
  COLUMN_NAMES.UPDATED_AT,
];

const PROPOSALS_COLUMNS = [
  COLUMN_NAMES.NAME,
  COLUMN_NAMES.ADDRESS,
  COLUMN_NAMES.PRIMARY_USER,
  COLUMN_NAMES.DESIRED_MEMBERSHIP,
  COLUMN_NAMES.SERVICE_LEVEL,
  COLUMN_NAMES.SIGNING_DATE,
  COLUMN_NAMES.MANAGER,
  COLUMN_NAMES.CREATED_AT,
  COLUMN_NAMES.UPDATED_AT,
];

const ACTIVE_PROJECTS_COLUMNS = [
  COLUMN_NAMES.NAME,
  COLUMN_NAMES.ADDRESS,
  COLUMN_NAMES.PRIMARY_USER,
  COLUMN_NAMES.DESIRED_MEMBERSHIP,
  COLUMN_NAMES.SERVICE_LEVEL,
  COLUMN_NAMES.ESTIMATED_DAY1_DATE,
  COLUMN_NAMES.TOS,
  COLUMN_NAMES.CREDIT_CARD,
  COLUMN_NAMES.MANAGER,
  COLUMN_NAMES.CREATED_AT,
  COLUMN_NAMES.UPDATED_AT,
];

const COMPLETED_PROJECTS_COLUMNS = [
  COLUMN_NAMES.NAME,
  COLUMN_NAMES.ADDRESS,
  COLUMN_NAMES.PRIMARY_USER,
  COLUMN_NAMES.DESIRED_MEMBERSHIP,
  COLUMN_NAMES.SERVICE_LEVEL,
  COLUMN_NAMES.DAY1_START_DATE,
  COLUMN_NAMES.CPP_EXPIRATION_DATE,
  COLUMN_NAMES.TOS,
  COLUMN_NAMES.CREDIT_CARD,
  COLUMN_NAMES.MANAGER,
  COLUMN_NAMES.CREATED_AT,
  COLUMN_NAMES.UPDATED_AT,
];

const LOST_PROJECTS_COLUMNS = [
  COLUMN_NAMES.NAME,
  COLUMN_NAMES.ADDRESS,
  COLUMN_NAMES.PRIMARY_USER,
  COLUMN_NAMES.LOST_REASON,
  COLUMN_NAMES.MANAGER,
];

const PRIMARY_PROJECTS_COLUMNS = [
  COLUMN_NAMES.NAME,
  COLUMN_NAMES.ADDRESS,
  COLUMN_NAMES.PRIMARY_USER,
  COLUMN_NAMES.PROJECT_STAGE,
  COLUMN_NAMES.MANAGER,
];

export interface PopupInfo {
  anchor: Element | null;
  project: PopulatedProject | null;
  dateName: 'signingDate' | 'estimatedDay1Date' | 'day1StartDate';
}

export const getProjectsColumns = ({
  activeView,
  setOwnerPopupInfo,
  classes,
  setDatePopupInfo,
  handleRowSelection,
  handleSortChange,
  sortSettings,
}: {
  handleRowSelection: (
    event: CellClickedEvent<PopulatedProject | ProjectGridDummy>,
  ) => void;
  setDatePopupInfo: React.Dispatch<React.SetStateAction<PopupInfo>>;
  classes: ClassNameMap;
  activeView: string;
  setOwnerPopupInfo: (
    {
      anchor,
      row,
    }: {
      anchor: Element | null;
      row: PopulatedProject;
    },
    data: PopulatedProject,
  ) => void;
  handleSortChange: (colId: string, sortDirection: SortDirection) => void;
  sortSettings: Record<string, SortDirection>;
}): DzColDef<PopulatedProject | ProjectGridDummy>[] => {
  const SortableHeaderCell = ({ ...props }: IHeaderParams) => (
    <DzGridSortableHeader
      {...props}
      onSortDirectionChange={handleSortChange}
    />
  );
  const columnsDictionary: Record<
    string,
    DzColDef<PopulatedProject | ProjectGridDummy>
  > = {
    [COLUMN_NAMES.NAME]: {
      colId: COLUMN_NAMES.NAME,
      headerName: 'Project name',
      headerComponent: SortableHeaderCell,
      headerComponentParams: {
        sortDirection: sortSettings[COLUMN_NAMES.NAME],
      },
      initialFlex: 1.5,
      toStringRepresentation: ({ data }) =>
        data.name || `Project for ${data.address?.address1}`,
      cellRenderer: ({ data }) => {
        if (isDummy(data)) {
          return <Skeleton variant="text" width={110} height={20} />;
        }
        return (
          <ProjectNameWithFlare
            name={data.name || `Project for ${data.address?.address1}`}
            isPrimary={data.ovprjid === data.address?.primaryProjectId}
          />
        );
      },
      valueGetter: ({ data }) => {
        if (!data || isDummy(data)) {
          return '';
        }
        return data?.name || `Project for ${data?.address?.address1}`;
      },
      onCellClicked: handleRowSelection,
      sortable: false,
    },
    [COLUMN_NAMES.ADDRESS]: {
      colId: COLUMN_NAMES.ADDRESS,
      headerComponent: SortableHeaderCell,
      headerComponentParams: {
        sortDirection: sortSettings[COLUMN_NAMES.ADDRESS],
      },
      headerName: 'Address',
      initialFlex: 1.5,
      cellRenderer: ({ data }) => {
        if (isDummy(data)) {
          return <Skeleton variant="text" width={110} height={20} />;
        }
        return data.address ? getAddressLabel(data.address) : '';
      },
      valueGetter: ({ data }) => {
        if (!data || isDummy(data)) {
          return '';
        }
        return data?.address ? getAddressLabel(data.address) : '';
      },
      onCellClicked: handleRowSelection,
    },
    [COLUMN_NAMES.PRIMARY_USER]: {
      colId: COLUMN_NAMES.PRIMARY_USER,
      headerComponent: SortableHeaderCell,
      headerComponentParams: {
        sortDirection: sortSettings[COLUMN_NAMES.PRIMARY_USER],
      },
      headerName: 'Primary User',
      initialFlex: 1,
      cellRenderer: ({ data }) => {
        if (isDummy(data)) {
          return <Skeleton variant="text" width={110} height={20} />;
        }

        return data?.address && data?.address?.primaryClient
          ? `${data.address.primaryClient.firstName} ${data.address.primaryClient.lastName}`
          : 'No primary client';
      },
      valueGetter: ({ data }) => {
        if (!data || isDummy(data)) {
          return '';
        }

        if (data?.address && data?.address?.primaryClient) {
          return `${data.address.primaryClient.firstName} ${data.address.primaryClient.lastName}`;
        }

        return 'No primary client';
      },
      onCellClicked: handleRowSelection,
    },
    [COLUMN_NAMES.DESIRED_MEMBERSHIP]: {
      colId: COLUMN_NAMES.DESIRED_MEMBERSHIP,
      headerComponent: SortableHeaderCell,
      headerComponentParams: {
        sortDirection: sortSettings[COLUMN_NAMES.DESIRED_MEMBERSHIP],
      },
      headerName: 'Desired Membership',
      cellRenderer: ({ data }) => {
        if (!data || isDummy(data)) {
          return <Skeleton variant="text" width={110} height={20} />;
        }

        return data.address?.clientSelectedMembership || '';
      },
      valueGetter: ({ data }) => {
        if (!data || isDummy(data)) {
          return '';
        }
        return data.address?.clientSelectedMembership || '';
      },
      onCellClicked: handleRowSelection,
    },
    [COLUMN_NAMES.SIGNING_DATE]: {
      colId: COLUMN_NAMES.SIGNING_DATE,
      headerComponent: SortableHeaderCell,
      headerComponentParams: {
        sortDirection: sortSettings[COLUMN_NAMES.SIGNING_DATE],
      },
      headerName: 'Est. Signing Date',
      type: [GridColumnTypes.CENTERED],
      initialFlex: 0.7,
      toStringRepresentation: ({ data }) =>
        data.signingDate
          ? format(
              coerceToDate(addZeroTime(data.signingDate)),
              'MMM dd, yyyy',
            )
          : defaultSigningDatePlaceholder,
      cellRenderer: ({ data }) => {
        if (isDummy(data)) {
          return <Skeleton variant="text" width={110} height={20} />;
        }
        const value = data.signingDate
          ? format(
              coerceToDate(addZeroTime(data.signingDate)),
              'MMM dd, yyyy',
            )
          : defaultSigningDatePlaceholder;
        return (
          <Tooltip title={'Change date'}>
            <span>{value}</span>
          </Tooltip>
        );
      },
      cellClass: classes.clickableDate,
      valueGetter: ({ data }) => {
        if (!data || isDummy(data)) {
          return '';
        }

        return data.signingDate;
      },
      onCellClicked: ({ event, data }) => {
        if (event && data && !isDummy(data)) {
          setDatePopupInfo({
            anchor: event.target as Element,
            project: data,
            dateName: 'signingDate',
          });
        }
      },
    },
    [COLUMN_NAMES.MANAGER]: {
      colId: COLUMN_NAMES.MANAGER,
      headerComponent: SortableHeaderCell,
      headerComponentParams: {
        sortDirection: sortSettings[COLUMN_NAMES.MANAGER],
      },
      headerName: 'Project Manager',
      type: [GridColumnTypes.CENTERED],
      initialFlex: 0.8,
      toStringRepresentation: ({ data }) => data.owner?.ownerName || '',
      cellRenderer: ({ data }) => {
        if (isDummy(data)) {
          return <Skeleton variant="text" width={110} height={20} />;
        }

        return data.owner
          ? getDzOwnerCellRawHtml(
              (data.owner as DzOwner & { ownerName: string }).ownerName,
              (data.owner as DzOwner & { isActive: boolean }).isActive,
            )
          : getDzOwnerCellRawHtml('', true);
      },
      valueGetter: ({ data }) => {
        if (!data || isDummy(data)) {
          return '';
        }
        return data.owner ? (data.owner as any).ownerName : ''; // eslint-disable-line @typescript-eslint/no-explicit-any
      },
      onCellClicked: ({ event, data }) => {
        if (event && data && !isDummy(data)) {
          setOwnerPopupInfo(
            {
              anchor: event.target as Element,
              row: data,
            },
            data,
          );
        }
      },
    },
    [COLUMN_NAMES.TOS]: {
      headerName: 'TOS',
      colId: COLUMN_NAMES.TOS,
      headerComponent: SortableHeaderCell,
      headerComponentParams: {
        sortDirection: sortSettings[COLUMN_NAMES.TOS],
      },
      type: [GridColumnTypes.CENTERED],
      initialFlex: 0.5,
      toStringRepresentation: ({ data }) => data.address?.tosAccepted,
      cellRenderer: ({ data }) => {
        if (isDummy(data)) {
          return <Skeleton variant="text" width={80} height={20} />;
        }

        return getDzCheckBoxRawHtml(
          data.address?.tosAccepted.toLowerCase() === 'yes',
        );
      },
      valueGetter: ({ data }) => {
        if (!data || isDummy(data)) {
          return 0;
        }

        return data.address?.tosAccepted.toLowerCase() === 'yes' ? 1 : 0;
      },
      onCellClicked: handleRowSelection,
    },
    [COLUMN_NAMES.CREDIT_CARD]: {
      colId: COLUMN_NAMES.CREDIT_CARD,
      headerComponent: SortableHeaderCell,
      headerComponentParams: {
        sortDirection: sortSettings[COLUMN_NAMES.CREDIT_CARD],
      },
      headerName: 'Membership Registered',
      type: [GridColumnTypes.CENTERED],
      initialFlex: 0.5,
      toStringRepresentation: ({ data }) =>
        data.address?.creditCardProvided,
      cellRenderer: ({ data }) => {
        if (isDummy(data)) {
          return <Skeleton variant="text" width={80} height={20} />;
        }
        return getDzCheckBoxRawHtml(
          data.address?.creditCardProvided.toLowerCase() === 'yes',
        );
      },
      valueGetter: ({ data }) => {
        if (!data || isDummy(data)) {
          return 0;
        }

        return data.address?.creditCardProvided.toLowerCase() === 'yes'
          ? 1
          : 0;
      },
      onCellClicked: handleRowSelection,
    },
    [COLUMN_NAMES.ESTIMATED_DAY1_DATE]: {
      colId: COLUMN_NAMES.ESTIMATED_DAY1_DATE,
      headerComponent: SortableHeaderCell,
      headerComponentParams: {
        sortDirection: sortSettings[COLUMN_NAMES.ESTIMATED_DAY1_DATE],
      },
      headerName: 'Est. Day1',
      initialFlex: 0.7,
      type: [GridColumnTypes.CENTERED],
      toStringRepresentation: ({ data }) =>
        data.estimatedDay1Date
          ? format(
              coerceToDate(addZeroTime(data.estimatedDay1Date)),
              'MMM dd, yyyy',
            )
          : defaultSigningDatePlaceholder,
      cellRenderer: ({ data }) => {
        if (isDummy(data)) {
          return <Skeleton variant="text" width={110} height={20} />;
        }
        const value = data.estimatedDay1Date
          ? format(
              coerceToDate(addZeroTime(data.estimatedDay1Date)),
              'MMM dd, yyyy',
            )
          : defaultSigningDatePlaceholder;

        return (
          <Tooltip
            title={
              data.projectStageId === DzProjectStage.ActiveProject
                ? 'Change date'
                : 'Day1 Submitted'
            }
          >
            <span>{value}</span>
          </Tooltip>
        );
      },
      cellClass: ({ data }) => {
        if (!data || isDummy(data)) {
          return '';
        }

        if (data.projectStageId === DzProjectStage.ActiveProject) {
          return classes.clickableDate;
        }

        return classes.disabledDate;
      },
      valueGetter: ({ data }) => {
        if (!data || isDummy(data)) {
          return '';
        }

        return data.estimatedDay1Date;
      },
      onCellClicked: ({ event, data }) => {
        if (!event || !data || isDummy(data)) {
          return;
        }
        if (data.projectStageId == DzProjectStage.ServiceClient) {
          return;
        }
        setDatePopupInfo({
          anchor: event.target as Element,
          project: data,
          dateName: 'estimatedDay1Date',
        });
      },
    },
    [COLUMN_NAMES.DAY1_START_DATE]: {
      colId: COLUMN_NAMES.DAY1_START_DATE,
      headerComponent: SortableHeaderCell,
      headerComponentParams: {
        sortDirection: sortSettings[COLUMN_NAMES.DAY1_START_DATE],
      },
      headerName: 'Day1',
      type: [GridColumnTypes.CENTERED],
      initialFlex: 0.7,
      cellRenderer: ({ data }) => {
        if (isDummy(data)) {
          return <Skeleton variant="text" width={110} height={20} />;
        }
        return data.day1StartDate
          ? format(
              coerceToDate(addZeroTime(data.day1StartDate)),
              'MMM dd, yyyy',
            )
          : defaultSigningDatePlaceholder;
      },
      valueGetter: ({ data }) => {
        if (!data || isDummy(data)) {
          return '';
        }

        return data.day1StartDate;
      },
      onCellClicked: handleRowSelection,
    },
    [COLUMN_NAMES.PROJECT_STAGE]: {
      colId: COLUMN_NAMES.PROJECT_STAGE,
      headerComponent: SortableHeaderCell,
      headerComponentParams: {
        sortDirection: sortSettings[COLUMN_NAMES.PROJECT_STAGE],
      },
      headerName: 'Project Stage',
      type: [GridColumnTypes.CENTERED],
      cellRenderer: ({ data }) => {
        if (isDummy(data)) {
          return <Skeleton variant="text" width={110} height={20} />;
        }

        return data.projectStage?.projectStage || 'N/A';
      },
      valueGetter: ({ data }) => {
        if (!data || isDummy(data)) {
          return '';
        }
        return data.projectStageId;
      },
      onCellClicked: handleRowSelection,
    },
    [COLUMN_NAMES.CREATED_AT]: {
      colId: COLUMN_NAMES.CREATED_AT,
      headerComponent: SortableHeaderCell,
      headerComponentParams: {
        sortDirection: sortSettings[COLUMN_NAMES.CREATED_AT],
      },
      headerName: 'Created at',
      type: [GridColumnTypes.CENTERED],
      initialFlex: 0.5,
      cellRenderer: ({ data }) => {
        if (isDummy(data)) {
          return <Skeleton variant="text" width={80} height={20} />;
        }
        return data.createdAt;
      },
      valueGetter: ({ data }) => {
        if (!data || isDummy(data)) {
          return '';
        }
        return data.createdAt;
      },
      onCellClicked: handleRowSelection,
    },
    [COLUMN_NAMES.UPDATED_AT]: {
      colId: COLUMN_NAMES.UPDATED_AT,
      headerComponent: SortableHeaderCell,
      headerComponentParams: {
        sortDirection: sortSettings[COLUMN_NAMES.UPDATED_AT],
      },
      headerName: 'Updated at',
      type: [GridColumnTypes.CENTERED],
      initialFlex: 0.5,
      cellRenderer: ({ data }) => {
        if (isDummy(data)) {
          return <Skeleton variant="text" width={80} height={20} />;
        }
        return data.updatedAt;
      },
      valueGetter: ({ data }) => {
        if (!data || isDummy(data)) {
          return '';
        }
        return data.updatedAt;
      },
      onCellClicked: handleRowSelection,
    },
    [COLUMN_NAMES.LOST_REASON]: {
      colId: COLUMN_NAMES.LOST_REASON,
      headerComponent: SortableHeaderCell,
      headerComponentParams: {
        sortDirection: sortSettings[COLUMN_NAMES.LOST_REASON],
      },
      headerName: 'Project Lost Reason',
      initialFlex: 1,
      cellRenderer: ({ data }) => {
        if (isDummy(data)) {
          return <Skeleton variant="text" width={110} height={20} />;
        }

        return data.projectLostReason?.projectLostReason || 'Other';
      },
      valueGetter: ({ data }) => {
        if (!data || isDummy(data)) {
          return '';
        }
        return data.projectLostReasonId;
      },
      onCellClicked: handleRowSelection,
    },
    [COLUMN_NAMES.CPP_EXPIRATION_DATE]: {
      colId: COLUMN_NAMES.CPP_EXPIRATION_DATE,
      headerComponent: SortableHeaderCell,
      headerComponentParams: {
        sortDirection: sortSettings[COLUMN_NAMES.CPP_EXPIRATION_DATE],
      },
      headerName: 'CPP Exp. Date',
      initialFlex: 0.7,
      type: [GridColumnTypes.CENTERED],
      cellRenderer: ({ data }) => {
        if (isDummy(data)) {
          return <Skeleton variant="text" width={110} height={20} />;
        }
        return data.address?.cppExpDate
          ? format(
              coerceToDate(addZeroTime(data.address?.cppExpDate)),
              'MMM dd, yyyy',
            )
          : defaultSigningDatePlaceholder;
      },
      valueGetter: ({ data }) => {
        if (!data || isDummy(data)) {
          return '';
        }

        return data.address?.cppExpDate ? data.address?.cppExpDate : '';
      },
      onCellClicked: handleRowSelection,
    },
    [COLUMN_NAMES.SERVICE_LEVEL]: {
      colId: COLUMN_NAMES.SERVICE_LEVEL,
      headerComponent: SortableHeaderCell,
      headerComponentParams: {
        sortDirection: sortSettings[COLUMN_NAMES.SERVICE_LEVEL],
      },
      headerName: 'Service Level',
      type: [GridColumnTypes.CENTERED],
      cellRenderer: ({ data }) => {
        if (isDummy(data)) {
          return <Skeleton variant="text" width={110} height={20} />;
        }
        return data.address?.clientActivatedMembership || '';
      },
      valueGetter: ({ data }) => {
        if (!data || isDummy(data)) {
          return '';
        }

        return data.address?.clientActivatedMembership || '';
      },
      onCellClicked: handleRowSelection,
    },
  };

  const columnListsByView: Record<string, COLUMN_NAMES[]> = {
    [ProjectsViews.proposals]: PROPOSALS_COLUMNS,
    [ProjectsViews.activeProjects]: ACTIVE_PROJECTS_COLUMNS,
    [ProjectsViews.completedProjects]: COMPLETED_PROJECTS_COLUMNS,
    [ProjectsViews.lostProjects]: LOST_PROJECTS_COLUMNS,
    [ProjectsViews.allPrimaryProjects]: PRIMARY_PROJECTS_COLUMNS,
    [ProjectsViews.allProjects]: ALL_PROJECTS_COLUMNS,
  };

  return (columnListsByView[activeView] || []).map(
    (key) => columnsDictionary[key],
  );
};
