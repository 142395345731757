import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ManagerAccountsIcon from '@mui/icons-material/ManageAccounts';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { auth } from '@one-vision/login';
import { useTimer } from 'shared-ui';

interface Props {
  onUserUpdate: () => void;
  onGroupsUpdate: () => void;
  onResendInvite: (options?: ResendInviteOptions) => void;
  onUserIdCopy: () => void;
  onUserDeactivate: () => void;
  onUserActivate: () => void;
  isActive: boolean;
}

export const DzUserOptions: React.FC<Props> = ({
  onResendInvite,
  onGroupsUpdate,
  onUserUpdate,
  onUserIdCopy,
  onUserDeactivate,
  onUserActivate,
  isActive,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const triggerRef = useRef(null);

  const [isTimerRunning, startTimer] = useTimer();

  const handleUserUpdate = () => {
    setMenuIsOpen(false);
    onUserUpdate();
  };

  const handleUserActivate = () => {
    setMenuIsOpen(false);
    onUserActivate();
  };

  const handleGroupsUpdate = () => {
    setMenuIsOpen(false);
    onGroupsUpdate();
  };
  const handleDeactivate = () => {
    setMenuIsOpen(false);
    onUserDeactivate();
  };
  const emitResendInvite = (options?: ResendInviteOptions) => {
    setMenuIsOpen(false);
    onResendInvite(options);
  };
  const handleCopy = useCallback(async () => {
    await onUserIdCopy();
    startTimer();
  }, [onUserIdCopy, startTimer]);

  const toggleMenu = () => setMenuIsOpen(!menuIsOpen);
  const closeMenu = () => setMenuIsOpen(false);
  const handleResendInvite = () => emitResendInvite();
  const handleResendInviteWithPassword = () =>
    emitResendInvite({ withPassword: true });

  return (
    <>
      <IconButton ref={triggerRef} onClick={toggleMenu}>
        <MoreVertIcon data-testid="activate-header-menu-list-user-side-panel" />
      </IconButton>
      <Menu
        open={menuIsOpen}
        anchorEl={triggerRef.current}
        onClose={closeMenu}
      >
        {auth.getIsAdmin() && isActive && (
          <>
            <MenuItem onClick={handleUserUpdate}>
              <ListItemIcon>
                <GroupAddIcon />
              </ListItemIcon>
              <Typography variant="inherit">Update user</Typography>
            </MenuItem>
            <MenuItem onClick={handleGroupsUpdate}>
              <ListItemIcon>
                <GroupAddIcon />
              </ListItemIcon>
              <Typography variant="inherit">
                Update user permissions
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleDeactivate}>
              <ListItemIcon>
                <ManagerAccountsIcon />
              </ListItemIcon>
              <Typography variant="inherit">Deactivate user</Typography>
            </MenuItem>
            <MenuItem onClick={handleResendInvite}>
              <ListItemIcon>
                <ForwardToInboxIcon />
              </ListItemIcon>
              <Typography variant="inherit">Re-Send invite</Typography>
            </MenuItem>
            <MenuItem onClick={handleResendInviteWithPassword}>
              <ListItemIcon>
                <ForwardToInboxIcon />
              </ListItemIcon>
              <Typography variant="inherit">
                Re-Send invite with password
              </Typography>
            </MenuItem>
          </>
        )}

        {auth.getIsAdmin() && !isActive && (
          <MenuItem onClick={handleUserActivate}>
            <ListItemIcon>
              <ManagerAccountsIcon />
            </ListItemIcon>
            <Typography variant="inherit">Activate user</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={handleCopy}>
          <ListItemIcon>
            {isTimerRunning ? <CheckIcon /> : <ContentCopyOutlinedIcon />}
          </ListItemIcon>
          <Typography variant="inherit">Copy User ID</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export interface ResendInviteOptions {
  withPassword?: boolean;
}
