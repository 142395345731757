import { format, coerceToDate } from '@one-vision/date-utils';
import { DzColDef, GridColumnTypes } from 'components/shared';
import { ClientOrClientToAdress } from 'core/redux';

export const usersColumns: Array<DzColDef<ClientOrClientToAdress>> = [
  {
    headerName: 'First Name',
    cellRenderer: ({ data }) => data.firstName,
    field: 'firstName',
  },
  {
    headerName: 'Last Name',
    cellRenderer: ({ data }) => data.lastName,
    field: 'lastName',
  },
  {
    headerName: 'Primary Email',
    flex: 1.3,
    cellRenderer: ({ data }) => data.email,
    field: 'email',
  },
  {
    headerName: 'Primary Phone',
    cellRenderer: ({ data }) => data.phone,
    field: 'phone',
  },
  {
    headerName: 'Updated At',
    type: [GridColumnTypes.CENTERED],
    cellRenderer: ({ data }) => {
      if (!data.updatedAt) {
        return '';
      }

      return format(coerceToDate(data.updatedAt));
    },
    getQuickFilterText: ({ data }) => {
      if (!data.updatedAt) {
        return '';
      }

      return format(coerceToDate(data.updatedAt));
    },
    valueGetter: ({ data }) => {
      if (!data?.updatedAt) {
        return '';
      }

      return format(coerceToDate(data.updatedAt));
    },
  },
  {
    sort: 'desc',
    headerName: 'Created At',
    type: [GridColumnTypes.CENTERED],
    cellRenderer: ({ data }) => {
      if (!data.createdAt) {
        return '';
      }

      return format(coerceToDate(data.createdAt));
    },
    getQuickFilterText: ({ data }) => {
      if (!data.createdAt) {
        return '';
      }

      return format(coerceToDate(data.createdAt));
    },
    valueGetter: ({ data }) => {
      if (!data?.createdAt) {
        return '';
      }

      return format(coerceToDate(data.createdAt));
    },
  },
];
