import { useCallback, useState } from 'react';
import { UserCommunicationPreferenceJsonApiEntity } from '@one-vision/json-api-parser';
import { API } from 'core/api';
import { actions, thunks } from 'core/redux';
import { useDispatch } from 'react-redux';
import { DzAsyncDispatch, UserCommunicationChannel } from 'shared-ui';
import { AxiosError } from 'axios';

export const MAX_COMMUNICATIONS = 3;
const MAX_PRIORITY = 30;
const STEP_OF_PRIORITY = 10;

export const COMMUNICATION_CHANNELS_WITH_PHONE = [
  UserCommunicationChannel.PhoneCall,
  UserCommunicationChannel.SMS,
];

export const useUserCreateOrUpdateDialog = () => {
  const [communicationLoading, setCommunicationLoading] = useState(false);

  const dispatch = useDispatch<DzAsyncDispatch>();

  const getUserCommunication = useCallback(
    async (userId: string) => {
      try {
        setCommunicationLoading(true);
        const {
          data: { included },
        } = await API.getUserDetails({
          filter: { userId },
          include: ['userCommunicationPreference'],
        });
        const communicationOnly = included.filter(
          (el) => el.type === 'UserCommunicationPreference',
        ) as UserCommunicationPreferenceJsonApiEntity[];
        let communication = communicationOnly
          .sort((a, b) =>
            a.attributes.priority > b.attributes.priority ? -1 : 1,
          )
          .map((el) => el.attributes.communicationChannel);
        communication = communication.concat(
          Array(MAX_COMMUNICATIONS - communication.length).fill(
            '',
          ) as string[],
        );

        return communication;
      } catch {
        dispatch(
          actions.updateSnackbar({
            type: 'error',
            text: 'Can`t retrieve comunnication preferences',
          }),
        );
        return [];
      } finally {
        setCommunicationLoading(false);
      }
    },
    [dispatch],
  );

  const updateUser = async (
    id: string,
    changes: Partial<{
      firstName: string;
      lastName: string;
      phoneNumber: string | null;
      isActive: boolean;
    }>,
  ) =>
    await API.patchUser({
      id,
      attributes: changes,
    });

  const updateCommunicationPreferences = async (
    userId: string,
    communications: string[],
    phoneIsNotPresent: boolean,
  ) => {
    let list = communications;

    if (phoneIsNotPresent) {
      list = list.map((communicationChannel) => {
        if (
          COMMUNICATION_CHANNELS_WITH_PHONE.includes(
            communicationChannel as UserCommunicationChannel,
          )
        ) {
          return UserCommunicationChannel.Email;
        }

        return communicationChannel;
      });
    }

    const payload = list
      .filter((el) => el)
      .map((el, index) => ({
        type: 'UserCommunicationPreference' as const,
        attributes: {
          communicationChannel: el as UserCommunicationChannel,
          priority: MAX_PRIORITY - STEP_OF_PRIORITY * index,
        },
      }));
    await API.postUserCommunicationPreference({
      userId,
      data: payload,
    });
  };

  const createUser = async (
    values: {
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string | null;
    },
    onClose: () => void,
    onError?: (error: string) => void,
  ) => {
    await dispatch(
      thunks.postOwner({
        owner: {
          ...values,
        },
      }),
    )
      .unwrap()
      .then((res) => {
        const error = (res as AxiosError)?.response?.data?.errors[0]
          ?.detail;

        dispatch(
          actions.updateSnackbar({
            type: !error ? 'success' : 'error',
            text: !error
              ? `The new user ${values.firstName} ${values.lastName} was created`
              : error || 'Can`t create user',
          }),
        );
        if (!error) {
          if (onError) {
            onError(error || 'Can`t create user');
          }
          return;
        }
        onClose();
      });
  };

  return {
    communicationLoading,
    getUserCommunication,
    updateUser,
    updateCommunicationPreferences,
    createUser,
  };
};
