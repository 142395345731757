import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ProjectsViews } from 'shared/hooks/makeUseFavoriteViews';
import { useProjectsViews } from './useProjectsViews';
import { getProjectsColumns, PopupInfo } from './dz-projects-columns';
import {
  fetchProjects,
  selectPopulatedProjectsFromList,
  updateProjectThunk,
} from 'core/redux/entities';
import { useDzSelector, actions, thunks } from 'core/redux';
import {
  ApiClient,
  CreatePrjResult,
  DzCreateProjectDialogProps,
  DzOwner,
  DzProject,
  ModalIDs,
  useModalManager,
  DzAddressPanelRelatedThunks,
  DzAsyncDispatch,
  DzProjectStage,
  ListReducerState,
  Projects,
  Clients,
  Addresses,
  Owners,
  DzPartner,
  usePartnerConfig,
} from 'shared-ui';
import { PopulatedProject, ProjectGridDummy, isDummy } from './types';
import {
  DEFAULT_GRID_ROW_HEIGHT,
  DEFAULT_PER_PAGE,
  URL_PARAM_NAMES,
} from 'shared/constants';
import { BodyScrollEvent, CellClickedEvent } from 'ag-grid-community';
import { useOwnerPopup } from 'shared/hooks';
import { AnyAction } from '@reduxjs/toolkit';
import { API } from 'core/api';
import { fetchOwners, selectOwners } from 'core/redux/owners.redux';
import { format, coerceToDate } from '@one-vision/date-utils';
import { usePreSearchList } from 'shared/hooks';
import { PreSearchLists } from 'shared/constants';
import { useStyles } from './dz-projects-page.styles';
import { makeCheckIsAnyDialogActive } from 'shared';
import { usePartner } from 'shared/hooks';
import {
  DzColDef,
  useOpenProjectSidebar,
  useSearchValue,
} from 'components/shared';
import { useWindowSize } from 'shared/hooks/useWindowSize';
import { COLUMN_NAMES } from './dz-projects-columns';
import { SortDirection } from 'types';

const DEFAULT_SCROLL_MULTIPLIER = 2;

interface UseProjectsHook {
  activeView: string;
  views: { id: ProjectsViews; text: string }[];
  favorites: ProjectsViews[] | undefined;
  handleViewChange: (viewId: string) => void;
  handleAddFavorite: (viewId: string) => void;
  handleRemoveFavorite: (viewId: string) => void;
  selectPrevView: () => void;
  selectNextView: () => void;
  searchText: string;
  activeViewPreSearchList: string[];
  onSearch: (searchText: string) => void;
  projectsColumns: DzColDef<PopulatedProject | ProjectGridDummy>[];
  rows: DzProject[];
  handleBodyScroll?: (event: BodyScrollEvent) => void;
  isReady: boolean;
  isLoadingMoreRows: boolean;
  ownerPopupInfo: {
    anchor: null | Element;
    row: PopulatedProject | null;
  };
  handleCloseOwnerSelection: () => void;
  handleUpdateOwner: (
    id: string,
    ownerId: DzOwner['ownerId'] | null,
    name: DzOwner['name'] | undefined,
  ) => AnyAction;
  handleAddProject: () => void;
  datePopupInfo: PopupInfo;
  closeDatePopup: () => void;
  handleDateChange: (date: Date | null) => void;
  setActiveViewPreSearchListItem: () => void;
  openSidebar: (ovprjid?: string) => void;
  closeSidebar: () => void;
  checkIsAnyDialogActive: (isActive: boolean) => void;
  fetchData: (options?: Partial<ListReducerState>) => void;
  searchValue: string;
}

const filtersDictionary: Record<
  string,
  Record<string, string | number | boolean>
> = {
  [ProjectsViews.proposals]: {
    projectStageId: DzProjectStage.Lead,
  },
  [ProjectsViews.activeProjects]: {
    projectStageId: DzProjectStage.ActiveProject,
  },
  [ProjectsViews.completedProjects]: {
    projectStageId: DzProjectStage.ServiceClient,
  },
  [ProjectsViews.lostProjects]: {
    projectStageId: DzProjectStage.LostProposal,
  },
  [ProjectsViews.allPrimaryProjects]: {
    isPrimary: true,
  },
};

export const useProjectsPage = (): UseProjectsHook => {
  const dispatch = useDispatch();

  const checkIsAnyDialogActive = makeCheckIsAnyDialogActive(dispatch);

  const classes = useStyles();

  const [, setSearchParams] = useSearchParams();

  const [sortSettings, setSortSettings] = useState<
    Record<string, SortDirection>
  >({ [COLUMN_NAMES.CREATED_AT]: 'desc' });

  const handleSortChange = useCallback(
    (colId: string, sortDirection: SortDirection) => {
      setSortSettings({
        [colId]: sortDirection,
      });
    },
    [setSortSettings],
  );

  const viewportBottomRef = useRef(0);
  const windowSize = useWindowSize();

  const [datePopupInfo, setDatePopupInfo] = useState<PopupInfo>({
    anchor: null,
    project: null,
    dateName: 'signingDate',
  });

  const handleDateChange = useCallback(
    (date: Date | null) => {
      if (!date || !datePopupInfo.project) {
        return;
      }

      const {
        project: { address, owner, ...project },
        dateName,
      } = datePopupInfo;

      const dateStr = format(coerceToDate(date));

      dispatch(
        updateProjectThunk({
          ...project,
          [dateName]: dateStr,
        }),
      );

      setDatePopupInfo({
        anchor: null,
        project: null,
        dateName: 'signingDate',
      });
    },
    [datePopupInfo, setDatePopupInfo, dispatch],
  );

  const closeDatePopup = useCallback(() => {
    setDatePopupInfo({
      anchor: null,
      project: null,
      dateName: 'signingDate',
    });
  }, [setDatePopupInfo]);

  const owners = useDzSelector(selectOwners);
  const products = useDzSelector(
    (state) => state.newProjectDialog.products,
  );

  const {
    activeView,
    views,
    favorites,
    handleViewChange,
    handleAddFavorite,
    handleRemoveFavorite,
    selectPrevView,
    selectNextView,
  } = useProjectsViews();

  const rows = useDzSelector((state) =>
    selectPopulatedProjectsFromList(state, activeView),
  );

  const clients = useDzSelector((state) => state.clients);
  const labels = useDzSelector((state) => state.identity);
  const partner = usePartner();

  const { openModal } = useModalManager();

  const {
    ownerPopupInfo,
    setOwnerPopupInfo,
    handleCloseOwnerSelection,
    handleUpdateOwner,
  } = useOwnerPopup<PopulatedProject>({
    updateOwner: ({ id, nameOwner, ownerId }) => {
      if (ownerId) {
        dispatch(
          Owners.actions.update({
            ownerId,
            ownerName: nameOwner,
          }),
        );
      }

      return Projects.actions.update({
        ovprjid: id,
        ownerId: ownerId,
      });
    },
  });

  const { isReady } = useDzSelector((state) =>
    Projects.selectors.selectList(state, activeView),
  );

  const filteredRows = useMemo(() => {
    const filterValues = filtersDictionary[activeView] || {};

    if (activeView !== ProjectsViews.activeProjects) {
      return rows.filter((row) => {
        return Object.entries(filterValues).every(
          ([key, value]) => row[key as keyof DzProject] === value,
        );
      });
    }

    return rows.filter((row) => {
      if (row.projectStageId === DzProjectStage.ActiveProject) {
        return true;
      }

      return coerceToDate(row.day1StartDate).valueOf() > Date.now();
    });
  }, [rows, activeView]);

  const [loadingMoreRows, setLoadingState] = useState(false);
  const [searchText, onSearch] = useState('');

  const partnerConfig = usePartnerConfig();

  const fetchData = useCallback(
    async (options: Partial<ListReducerState> = {}) => {
      setLoadingState(true);

      const sort: string[] = Object.entries(sortSettings).reduce<string[]>(
        (acc, [colId, value]) => {
          if (!value) {
            return acc;
          }
          const keys = colId.split(',');
          const sortValues = keys.map((key) =>
            value === 'desc' ? `-${key}` : key,
          );

          return [...acc, ...sortValues];
        },
        [],
      );
      await dispatch(
        fetchProjects(
          {
            ...options,
            filter: {
              ...(options.filter || {}),
              ...(filtersDictionary[activeView] || {}),
            },
            sort,
          },
          activeView,
        ),
      );

      await dispatch(fetchOwners());
      setLoadingState(false);
    },
    [dispatch, activeView, sortSettings],
  );

  useEffect(() => {
    dispatch(Projects.actions.updateList({ isReady: false }, activeView));
    viewportBottomRef.current = 0;

    /**
     * Load at least two full scrolls of projects
     * Minimum: 50 rows
     */
    const perPage =
      (DEFAULT_GRID_ROW_HEIGHT * DEFAULT_PER_PAGE) / windowSize.height >
      DEFAULT_SCROLL_MULTIPLIER
        ? DEFAULT_PER_PAGE
        : Math.floor(
            (DEFAULT_SCROLL_MULTIPLIER * windowSize.height) /
              DEFAULT_GRID_ROW_HEIGHT,
          );

    fetchData({
      page: 1,
      perPage,
      include: [
        'address>primaryClient',
        'address>primaryProject',
        'owners',
        'projectLostReason',
        'projectStage',
      ],
      search: searchText
        ? {
            fields: [
              'name',
              'projectStage.projectStage',
              'owners.firstName',
              'owners.lastName',
              'projectLostReason.projectLostReason',
              'address.address1',
              'address.address2',
              'address.businessName',
              'address.city',
              'address.clientSelectedMembership',
              'address.clientActivatedMembership',
              'address.primaryClient.firstName',
              'address.primaryClient.lastName',
            ],
            value: searchText,
          }
        : undefined,
    });
  }, [fetchData, searchText, dispatch]); // eslint-disable-line

  const handleRowSelection = useCallback(
    ({ data }: CellClickedEvent<PopulatedProject | ProjectGridDummy>) => {
      if (!data || isDummy(data)) {
        return;
      }
      const { ovprjid, ovaid } = data;

      setSearchParams((oldParams) => {
        oldParams.set(URL_PARAM_NAMES.SELECTED, ovprjid);
        return [...oldParams];
      });

      dispatch(
        actions.updateOrganizationDialogState({
          isOpen: true,
          ovaid: ovaid,
          context: {
            recordId: ovaid,
            ovprjid,
          },
        }),
      );
    },
    // setSearchParams is not a stable function, do not include it in deps array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const [prjProposalsPreSearchList, prjProposalsAddToPreSearchList] =
    usePreSearchList(searchText, PreSearchLists.prjProposals);
  const [prjActivePreSearchList, prjActiveAddToPreSearchList] =
    usePreSearchList(searchText, PreSearchLists.prjActive);
  const [prjCompletedPreSearchList, prjCompletedAddToPreSearchList] =
    usePreSearchList(searchText, PreSearchLists.prjCompleted);
  const [prjLostPreSearchList, prjLostAddToPreSearchList] =
    usePreSearchList(searchText, PreSearchLists.prjLost);
  const [prjPrimaryPreSearchList, prjPrimaryAddToPreSearchList] =
    usePreSearchList(searchText, PreSearchLists.prjPrimary);
  const [prjAllPreSearchList, prjAllAddToPreSearchList] = usePreSearchList(
    searchText,
    PreSearchLists.prjAll,
  );

  const getPreSearchListHook: () => [string[], () => void] = () => {
    switch (activeView) {
      case ProjectsViews.proposals:
        return [prjProposalsPreSearchList, prjProposalsAddToPreSearchList];
      case ProjectsViews.activeProjects:
        return [prjActivePreSearchList, prjActiveAddToPreSearchList];
      case ProjectsViews.completedProjects:
        return [prjCompletedPreSearchList, prjCompletedAddToPreSearchList];
      case ProjectsViews.lostProjects:
        return [prjLostPreSearchList, prjLostAddToPreSearchList];
      case ProjectsViews.allPrimaryProjects:
        return [prjPrimaryPreSearchList, prjPrimaryAddToPreSearchList];
      case ProjectsViews.allProjects:
        return [prjAllPreSearchList, prjAllAddToPreSearchList];
      default:
        return [
          [],
          () => {
            return;
          },
        ];
    }
  };

  const [activeViewPreSearchList, setActiveViewPreSearchListItem] =
    getPreSearchListHook();

  const projectsColumns = useMemo(() => {
    let columns = getProjectsColumns({
      activeView,
      setOwnerPopupInfo,
      classes,
      setDatePopupInfo,
      handleRowSelection,
      handleSortChange,
      sortSettings,
    });

    if (
      !partnerConfig.loading &&
      !partnerConfig.accessSubscriptionManagement
    ) {
      columns = columns.filter(
        (el) => el.colId !== COLUMN_NAMES.CREDIT_CARD,
      );
    }

    return columns;
  }, [
    activeView,
    classes,
    handleRowSelection,
    setOwnerPopupInfo,
    handleSortChange,
    sortSettings,
    partnerConfig,
  ]);

  const handleBodyScroll = useMemo(
    () => (event: BodyScrollEvent) => {
      const height = rows.length * DEFAULT_GRID_ROW_HEIGHT;
      const viewportBottom = event.api.getVerticalPixelRange().bottom;
      if (viewportBottomRef.current >= viewportBottom) {
        return;
      }
      if (loadingMoreRows) {
        return;
      }

      viewportBottomRef.current = viewportBottom;
      if (height - viewportBottom >= 0 && height - viewportBottom < 150) {
        fetchData();
      }
    },
    [rows, fetchData, loadingMoreRows],
  );

  const handleAddProject = useCallback(async () => {
    const result = await openModal<
      DzCreateProjectDialogProps,
      CreatePrjResult | undefined
    >(ModalIDs.createProject, {
      apiClient: API as unknown as ApiClient,
      actions,
      dispatch: dispatch as DzAsyncDispatch,
      owners,
      products,
      thunks: thunks as DzAddressPanelRelatedThunks,
      allClients: clients,
      labels,
      zendesk: false,
      partner: partner as DzPartner,
    });

    if (!result) {
      return;
    }

    const { newAddress, newClient, newProject } = result;

    dispatch(Projects.actions.update(newProject, activeView));
    dispatch(Clients.actions.update(newClient));
    if (newAddress) {
      dispatch(Addresses.actions.update(newAddress));
    }
  }, [
    openModal,
    dispatch,
    owners,
    products,
    activeView,
    clients,
    labels,
    partner,
  ]);

  const openSidebar = useOpenProjectSidebar({ actions });

  const closeSidebar = useCallback(() => {
    setSearchParams((prev) => {
      prev.delete(URL_PARAM_NAMES.SELECTED);
      return [...prev];
    });
    dispatch(
      actions.updateOrganizationDialogState({
        isOpen: false,
        ovaid: '',
        context: undefined,
      }),
    );
  }, [dispatch, setSearchParams]);

  const searchValue = useSearchValue();

  return {
    searchValue,
    isReady,
    isLoadingMoreRows: loadingMoreRows || partnerConfig.loading,
    projectsColumns,
    activeView,
    views,
    favorites: favorites['project'],
    activeViewPreSearchList,
    handleViewChange,
    handleAddFavorite,
    handleRemoveFavorite,
    selectNextView,
    selectPrevView,
    searchText,
    onSearch,
    rows: filteredRows as DzProject[],
    ownerPopupInfo,
    handleCloseOwnerSelection,
    handleUpdateOwner,
    handleAddProject,
    datePopupInfo,
    closeDatePopup,
    handleDateChange,
    setActiveViewPreSearchListItem,
    openSidebar,
    closeSidebar,
    checkIsAnyDialogActive,
    handleBodyScroll,
    fetchData,
  };
};
