import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';
import { logRender } from '@one-vision/utils';

import { actions, thunks, useDzSelector } from 'core/redux';
import { useStyles } from './dz-owner-selection-popup.styles';
import { selectOwners } from 'core/redux/owners.redux';
import {
  Autocomplete,
  ClickAwayListener,
  Paper,
  Popper,
  TextField,
} from '@mui/material';
import { DzOwnerListOption, DzRelativePopper, DzOwner } from 'shared-ui';

interface DzOwnerSelectionPopupProps {
  onClose: () => void;
  onGetOnUpdateAction: (
    id: string,
    ownerId: DzOwner['ownerId'] | null,
    name: DzOwner['name'] | undefined,
  ) => Action;
  anchor?: Element;
  ownerId: string;
  ownerType: 'project' | 'address';
  projectId?: string;
  addressId?: string;
  id: string;
}

export const DzOwnerSelectionPopupView: React.FC<
  DzOwnerSelectionPopupProps
> = ({
  ownerType,
  addressId,
  projectId,
  id,
  anchor,
  onGetOnUpdateAction,
  onClose,
}) => {
  logRender(DzOwnerSelectionPopupView);

  const classes = useStyles();
  const owners = useDzSelector(selectOwners);
  const options = useMemo<DzOwner[]>(() => {
    const unassigned = {
      name: null,
      ownerId: null,
    };
    return [
      unassigned as unknown as DzOwner,
      ...owners.filter((item) => item.isActive),
    ];
  }, [owners]);
  const dispatch = useDispatch();

  const open = Boolean(anchor);

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchor}
      style={{ minWidth: '300px' }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper>
          <Autocomplete
            autoHighlight
            open
            disablePortal
            popupIcon={null}
            PopperComponent={DzRelativePopper}
            renderInput={({ InputProps, inputProps, ...params }) => (
              <TextField
                autoFocus
                {...params}
                InputProps={{
                  ...InputProps,
                  disableUnderline: true,
                  classes: {
                    root: classes.inputRoot,
                  },
                }}
                inputProps={{
                  ...inputProps,
                  'aria-label': 'Choose an account manager',
                }}
                margin="none"
                variant="standard"
                placeholder="Assign to..."
              />
            )}
            options={options}
            getOptionLabel={(option) => option.name || 'Unassigned'}
            renderOption={(props, option) => (
              <DzOwnerListOption
                {...props}
                name={option.name}
                key={option.ownerId}
              />
            )}
            onClose={(_, reason) => {
              if (reason === 'escape') {
                onClose();
              }
            }}
            onChange={(_: unknown, option) => {
              if (!option) {
                return;
              }
              dispatch(
                onGetOnUpdateAction(
                  id,
                  option.ownerId || null,
                  option.name,
                ),
              );
              const updateAction =
                ownerType === 'project'
                  ? thunks.updateProject({
                      projectId: projectId as string,
                      changes: { ownerId: option.ownerId },
                    })
                  : thunks.updateAddress({
                      ovaid: addressId as string,
                      changes: { ownerId: option.ownerId },
                    });

              dispatch(updateAction);
              dispatch(
                actions.updateSnackbar({
                  type: 'success',
                  text: 'Project Owner Updated',
                }),
              );
              onClose();
            }}
          />
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};
